

.header {
    width: auto;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
  }
  
.header .navbar-header{
    width: 250px;
    text-align: start;
    color: #5800ac;
}
.navbar-header{
    color: #5800ac;
    margin-left: 25px;
}
.input-search {
    position: relative;
    margin-left: 20px;
   }
  
  .input-search input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    border-radius: 25px;
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: #fff;
    outline: none;
  }

.flag{
    margin-left:auto;
    display: flex;
    align-items: center;
}
.language{
    margin-left: 10px;
}
.arrow-icon{
    margin-left: 10px;
}
.date{
    margin-left: 20px;
}


   @media (max-width: 768px) {
   .navbar-flag {
    display: none;
  }
  
  .sidebar-flag {
    display: flex;
  }

  .profile-image1{
    width: 40px;
    border-radius: 50%;
    height: 40px;
}
.user-profile1{
    display: flex;
    align-items: center;
    margin-left: 30px;
}
.profile-image{
   display: none;
}
.user-profile{
    display: none;
}
}
  /* Media query for larger screens */
  @media (min-width: 768px) {
    /* Show .flag in navbar and hide in sidebar for larger screens */
    .navbar-flag {
      display: flex;
      align-items: center;
    }
  
    .sidebar-flag {
      display: none;
    }
    .profile-image{
        width: 40px;
        border-radius: 50%;
        height: 40px;
    }
    .user-profile{
        display: flex;
        align-items: center;
        margin-left: 30px;
    }
    .profile-image1{
        display: none;
    }
    .user-profile1{
        display: none;
    }
  }
.language{
    margin-left: 10px;
}
.arrow-icon{
    margin-left: 10px;
}
.date{
    margin-left: 20px;
}
.message{
    margin-left: 20px;
}
.notification1{
    margin-left: 20px;
}

.user-name{
    margin-left: 10px;
}
.user-name .name{
    margin:0;
}
.user-name .role{
    margin:0;
    font-size: 12px;
}


/*below is sidebar css  */

.sidebar-container {
    width: 230px;
    text-align: start;
    padding: 0 10px 0 0;
    background-color: #fff;
}
.sidebar-body .sidebar-contain{
    padding: 10px;
    display: flex;
    align-items: center;
    
}

.sidebar-icon{
    margin: 0 10px;
}

.sidebar-contain {
    padding: 10px 0;
    cursor: pointer; /* Optional: Add cursor pointer to indicate clickable */
  }
  
.sidebar-contain.active {
    background-color:  #5800ac;
    color: white !important; /* Optional: Adjust text color for better contrast */
    border-radius: 0 25px 25px 0;
}

.sidebar-footer {
    margin-top: 150%; /* Push footer to the bottom of the sidebar */
  }
  
  .sidebar-footer .sidebar-footer-contain {
    padding: 10px;
    cursor: pointer;
    display: flex;
  }
  
  .sidebar-footer .sidebar-footer-contain:hover {
    background-color: #f0f0f0; /* Optional: Highlight on hover */
  }

  