.payment-page{
    background-color: white;
    padding: 10px 10px 10px 10px;
    margin-top: 10px;
    border-radius: 10px;
}
.payment-tagline{
    font-size: 13px;
    margin-top: 10px !important;
}
.payment-form{
    padding: 5px;
}
.bill-address{
    background-color: aliceblue;
    padding: 5px 15px;
    
}