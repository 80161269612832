/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
  overflow: hidden; /* Prevents the background from scrolling when modal is open */
}

  
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 15px;
  width: auto;
  min-width: 500px;
  max-width: 100%;
  max-height: 100%; /* Ensures the modal doesn't exceed the viewport height */
  display: flex;
  flex-direction: column; /* Allows flexbox to work properly for header, body, and footer */
  overflow: hidden; /* Prevents content overflow */
  position: relative;
}

.font-change{
  font-size: 15px;
}

.font-change1{
  font-size: 15px;
  color: #484847;
}
.modal-body {
  flex-grow: 1;
  overflow-y: auto; /* Makes the content scrollable */
  overflow-x: hidden; /* Makes the content scrollable */
  padding: 10px 0; /* Add some padding for better visibility */
}
  
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
  .modal-close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  
  .form-group {
    margin-bottom: 15px;
  }
  
  /* .form-group label {
    display: block;
    margin-bottom: 5px;
  }
   */
  .form-buttons {
    display: flex;
    justify-content: center;
  }
  
  .import-btn {
    padding: 5px 10px;
    border: none;
    background: linear-gradient(90deg, #8800ff, #4a00e0);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
  }
.addleads-btn{
    padding: 5px 10px;
    border: none;
    background: linear-gradient(90deg, #1fb800, #007200);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 5px;
}
  .import-btn:hover {
    background: linear-gradient(90deg, #4a00e0, #8800ff);
  }
  .addleads-btn:hover {
    background: linear-gradient(90deg, #007200, #1fb800);
  }
.header-name{
    color:#4a00e0;
}
/* Wrapper for the custom file input */
.file-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    /* max-width: 300px; Adjust the max width as needed */
    margin: 0 auto; /* Centering */
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    background-color: #f9f9f9; /* Background color for input area */
}

/* Hide the actual file input */
.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

/* Label styling for the custom file input */
.file-input-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* padding: 10px; */
    cursor: pointer;
}

/* Styling for the text part of the custom input */
.file-input-text {
    flex-grow: 1;
    color: #757575;
    padding-left: 10px;
    font-size: 16px;
}

/* Styling for the button part of the custom input */
.file-input-button {
    background-color: #f5f5f5; /* Adjust background color */
    color: #6a0dad; /* Button text color */
    padding: 10px 15px;
    border-left: 1px solid #ccc;
    border-radius: 0 5px 5px 0;
    font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .file-input-wrapper {
        max-width: 100%; /* Adjust width for smaller screens */
    }

    .file-input-label {
        /* padding: 8px; Adjust padding */
    }

    .modal-content {
      background: white;
      padding: 20px;
      border-radius: 15px;
      width: auto;
      min-width: 300px;
      max-width: 90%;
      max-height: 90%; /* Ensures the modal doesn't exceed the viewport height */
      display: flex;
      flex-direction: column; /* Allows flexbox to work properly for header, body, and footer */
      overflow: hidden; /* Prevents content overflow */
      position: relative;
    
      }

    .file-input-text, .file-input-button {
        font-size: 14px; /* Adjust font size */
    }
}
