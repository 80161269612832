.pagination-container {
    display: flex;
    justify-content: end;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 10px;
    
  }
  
  .page-link {
    padding: 5px 0px;
    border: 1px solid #ccc;
    height: auto;
    width: 35px;
    background-color: #f5f5f5 ;
    text-align: center;
    align-content: center;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
  }
  
  .page-link:hover {
    background-color: #f5f5f5;
  }
  
  .page-item.active .page-link {
    background-color: #5800ac;
    color: white;
    border-color: #5800ac;
  }
  