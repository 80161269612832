/* Base Styles */
.page-position {
    color: gray;
}

.leads-header {
    padding: 8px 0;
}

.lead-head {
    display: flex;
    justify-content: end;
    background-color: white;
    margin-top: 8px;
    border-radius: 10px;
}
.users-head{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    border-radius: 10px;
}

.head-content {
    margin: 10px 5px;
    border: 1px solid black;
    padding: 3px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.user-head-content{
    margin: 0px 5px 10px 5px;
    padding:  5px 10px;
    border: 0.5px solid black;
    border-radius: 4px;
    background-color: white;
    align-content: center;
}
.user-add-btn {
    margin: 0px 15px 10px 5px;
    background: linear-gradient(90deg, #4a00e0, #8800ff);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 16px;
    align-content: center;
    color: white;
}
.mr-6{
    margin-right: 3px;
}
.add-btn {
    margin: 10px 15px 10px 5px;
    background-color: #8000804f;
    padding: 0px 5px;
    border-radius: 10px;
    font-size: 20px;
    color: purple;
}

.leads-section {
    width: 100%;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
}

/* Filtration Section as Sidebar */
.filtration-section {
    width: 25%;
    padding: 10px 0;
    background-color: white;
    border-radius: 10px;
}

.table-section {
    width: 74%;
    padding: 10px 0;
    background-color: white;
    border-radius: 10px;
}

.filter-tabs {
    background-color: #f1f5fb;
    margin: 0 10px;
    padding: 5px 0;
    display: flex;
    border-radius: 50px;
}
.table-select-filter{
    background: none;
    border: none;
}

.filter-tabs-content {
    text-align: center;
    width: 50%;
    padding: 5px 0;
    cursor: pointer;
}

.filter-tabs-content-active {
    background-color: white;
    color: #5800ac;
    padding: 5px 0;
    margin: 0 5px;
    border-radius: 50px;
}

.filter-search {
    position: relative;
    margin: 10px;
}

.filter-search input {
    width: 100%;
    padding: 5px 35px 5px 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: #f1f5fb;
    outline: none;
}

.filter-button {
    margin: 10px 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.filter-name {
    color: #5800ac;
    width: 85%;
    padding: 5px 0 5px 10px;
}

.filter-toggle {
    background-color: #f1f5fb;
    padding: 5px 10px;
    align-content: center;
}

.filter-content {
    padding: 0 10px;
    margin: -10px 10px 0 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.filter-input {
    width: calc(100% - 20px); /* 10px padding on each side */
    padding: 5px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.filter-toggle-switch {
    display: flex;
    align-items: center;
}

.toggle-input {
    display: none;
}

.toggle-label {
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggle-input:checked + .toggle-label {
    background-color: purple;
}

.toggle-input:checked + .toggle-label:before {
    transform: translateX(20px);
}

.filter-footer {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
}

.filter-clear {
    padding: 5px 25px;
    align-content: center;
    border-radius: 5px;
    border: 1px solid #ffbb00;
    color: #ffbb00;
    cursor: pointer;
}

.filter-search-btn {
    padding: 5px 25px;
    align-content: center;
    border-radius: 5px;
    border: 1px solid #5800ac;
    color: #5800ac;
    cursor: pointer;
}

/* Responsive Design */

/* Larger Screens */
@media (min-width: 768px) {
    .leads-section {
        flex-direction: row;
    }

    .filtration-section {
        position: static;
        width: 25%;
        left: 0; /* Keep the sidebar visible in desktop view */
        max-width: none; /* Remove the max width */
    }

    .table-section {
        width: 74%;
    }

    .filter-toggle-btn {
        display: none; /* Hide the button on larger screens */
    }
}


/* Smaller Screens */
@media (max-width: 767px) {
    .leads-section {
        flex-direction: column;
    }
    .filter-toggle-btn {
        display: block; /* Show the toggle button on mobile */
    }

    .filtration-section {
        width: 75%; /* Adjust the width of the sidebar for mobile view */
    }

    .table-section {
        width: 100%; /* Ensure the table section takes full width when sidebar is open */
        margin-left: 0; /* No margin when sidebar is open */
    }
    .filtration-section {
        width: 100%;
        max-width: 300px; /* Define the max width of the sidebar */
        padding: 10px 0;
        background-color: white;
        border-radius: 10px;
        position: fixed;
        top: 0;
        left: -300px; /* Hide sidebar off the screen by default */
        height: 100%;
        overflow-y: auto;
        transition: left 0.3s ease;
        z-index: 1000; /* Ensure the sidebar overlays other content */
    }
    
    .filtration-section.open {
        left: 0; /* Slide in the sidebar when open */
    }

    .filter-toggle-btn {
        /* display: none; Hide the button by default */
        /* position: fixed; */
        top: 10px;
        right: 10px;
        background-color: #f9fbff;
        color: black;
        border: none;
        border-radius: 5px;
        padding: 0 10px;
        cursor: pointer;
        z-index: 1; /* Ensure it is above the sidebar */
    }
    
    .filter-toggle-btn svg {
        vertical-align: middle;
    }

    .table-section {
        width: 100%;
    }
}
.table-btn{
    display: flex;
    
}
.table-btn-sort{
    display: flex;
}
.table-btn-filter{
    background-color: #f9fbff;
    padding: 5px 6px;
    font-size: 14px;
    margin: 10px 5px;
    border-radius:5px ;
}
.table-sort-filter{
    background-color: #f9fbff;
    padding: 5px 10px;
    margin: 10px 5px;
    font-size: 14px;
    border-radius:5px ;
}
.table-image{
    width: 40px;
    margin-right: 10px;
}

/* Dropdown container */
.dropdown1 {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown button */
  .dropdown-toggle1 {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* Dropdown menu */
  .dropdown-menu1 {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
    top: 100%;
    min-width: 160px;
    padding: 10px;
    border-radius: 5px;
  }
  
  /* Dropdown item */
  .dropdown-item1 {
    color: #5800ac;
    /* padding: 10px; */
    text-decoration: none;
    display: block;

  }
  .dropdown-text{
    font-size: 18px;
  }
  
  /* Hover effect for dropdown items */
  .dropdown-item1:hover {
    background-color: #f1f1f1;
  }
  
  /* Show the dropdown menu when the dropdown button is clicked */
  .dropdown1.show .dropdown-menu1 {
    display: block;
  }
  .social-icon{
    display: flex;
    justify-content: space-between;
  }
  .social-icon1{
    display: flex;
    /* justify-content: space-between; */
  }
  .table-section-home{
    background-color: white;
    border-radius: 15px;
    margin: 0 10px;
  }

  .completed-class{
    background-color: #f6fefc;
    color: #2e9d83;
    border: 1px solid #2e9d83;
    padding: 5px 10px;
    text-align: center;
    width: 80px;
    border-radius: 5px;
  }
  .failed-class{
    background-color: #fff8f8;
    color: #e11313;
    border: 1px solid #e11313;
    padding: 5px 10px;
    text-align: center;
    width: 80px;
    border-radius: 5px;
  }
  .pending-class{
    background-color: #ffffff;
    color: #e8d520;
    border: 1px solid #e8d520;
    padding: 5px 10px;
    text-align: center;
    width: 80px;
    border-radius: 5px;
  }
.pagination-footer{
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    flex-wrap: wrap;
}
.paginate-footer-content{
    /* padding: 10px 0px; */
    color: #a1a1a1;
}


@keyframes slideIn {
    from {
      right: -300px; /* Start off-screen */
    }
    to {
      right: 20px; /* Slide into view */
    }
  }
  
  @keyframes slideOut {
    from {
      right: 20px; /* In view */
    }
    to {
      right: -300px; /* Slide off-screen */
    }
  }
  
  .export-message {
    position: fixed;
    bottom: 20px;
    right: -300px; /* Start off-screen */
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    z-index: 1000;
  }
  
  .export-message.show {
    animation: slideIn 1s forwards, slideOut 1s forwards 3s; /* Slide in and out after 3 seconds */
  }
  
  .export-success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .export-fail {
    background-color: #f8d7da;
    color: #721c24;
  }
  .truncate {
    white-space: nowrap;       /* Prevents the text from wrapping to the next line */
    overflow: hidden;          /* Hides any overflowing content */
    text-overflow: ellipsis;   /* Adds ellipses (...) when the text overflows */
    max-width: 150px;          /* Set a maximum width for the text (adjust as needed) */
    /* display: inline-block;     Ensure it respects the max-width */
    vertical-align: middle;    /* Aligns text vertically in the middle */
}
.truncate-data{
    display: flex;
    max-width: 150px;  
    /* white-space: nowrap;       Prevents the text from wrapping to the next line */
    /* overflow: hidden;          Hides any overflowing content
    text-overflow: ellipsis;
    vertical-align: middle;  */
}
.truncate-data-text{
    
    white-space: nowrap;       /* Prevents the text from wrapping to the next line */
    overflow: hidden;          /* Hides any overflowing content */
    text-overflow: ellipsis;
    max-width: 100px;  
    vertical-align: middle; 
}
.company-img{
    align-content: center;
}

.tooltip1 {
    position: relative;
    display: inline-block;
  }
  
  .tooltip1 .tooltiptext1 {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
  }
  
  .tooltip1 .tooltiptext1::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
  
  .tooltip1:hover .tooltiptext1 {
    visibility: visible;
  }

  .tooltip2 {
    position: relative;
    display: inline-block;
  }
  
  .tooltip2 .tooltiptext2 {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1000;
    top: 150%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip2 .tooltiptext2::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  
  .tooltip2:hover .tooltiptext2 {
    visibility: visible;
  }

  .social-icon-deep{
    padding: 0 5px 0 0;
  }