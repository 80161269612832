.pricing-page{
    background-color: white;
    padding: 30px 10px 10px 10px;
    margin-top: 10px;
    text-align: center;
    border-radius: 10px;
}
.pricing-tagline{
    background-color: #f1f5fb;
    padding: 5px 15px;
    border-radius: 50px;
    color: #5800ac;
}
.pricing-head{
    margin-top: 10px;
    color: #320061;
}
.pricing-subhead{
    margin-top: 10px;
    color: #5800ac;
}

.pricing-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.pricing-toggle span{
    color: #5800ac;
}
.switch {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #5800ac;
}

input:checked + .slider:before {
    transform: translateX(14px);
}
.icon-back{
    background-color: #f1f5fb;
    width: 50px;
    height:50px;
    border-radius: 50px;
    align-content: center;
    margin: auto;
}
.icon-back-extra{
    background-color: #cfe2ff;
    width: 35px;
    height:35px;
    border-radius: 50px;
    align-content: center;
    margin: auto;
}
.pricing-plan-card{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 10px auto;
    justify-content: center;
    padding: 20px 10px;
    border-radius: 10px;
    width: 30%;
}
@media (max-width: 768px) {
    .pricing-plan-card{
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        margin: 10px auto;
        justify-content: center;
        padding: 20px 10px;
        border-radius: 10px;
        width: 90%;
    }
}
.plan-title{
    color: #5800ac;
    font-weight: 600 !important;
    margin: 10px 0 20px 0;
}
.plan-rate{
    font-size: 25px;
    margin: 10px 0 20px 0 !important;
}
.plan-sub-head{
    margin-bottom: 20px !important;
}
.plan-body{
    margin: 10px 0 0 0;
}
.plan-btn{
    width: 100%;
    border-radius: 10px;
    margin: 25px 0 5px 0;
    text-align: center;
    background:linear-gradient(90deg, #4a00e0, #8e2de2);
    color: white;
    padding: 10px 0;
}
.plan-btn:hover{
    width: 100%;
    border-radius: 10px;
    margin: 25px 0 5px 0;
    text-align: center;
    background:linear-gradient(90deg, #8e2de2, #4a00e0);
    color: white;
    padding: 10px 0;
}