body, html {
    height: 100%;
    margin: 0;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
  }
  
  .login-form {
    background-color: white;
    width: 450px;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    
    /* Add shadow */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Zero horizontal and vertical offset, 20px blur */
}
  
  .login-header {
    font-size: 18px;
    
  }
  
  .login-name {
    color: #5800ac;
    margin-bottom: 10px !important ;
  }

  .google-btn{
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Zero horizontal and vertical offset, 20px blur */

  }

  .or-divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
  }
  
  .or-divider .line {
    flex-grow: 1; /* This makes the lines grow to fill the available space */
    height: 2px; /* Line height */
    background-color: #dfdfdf; /* Line color */
  }
  
  .or-divider .text {
    margin: 0 10px; /* Spacing around the text */
    font-weight: bold;
    color: #000; /* Text color */
  }

  .input-group1 {
    position: relative;
    margin-bottom: 20px;
  }
  
  .input-group1 input {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: #f7f7f7;
    outline: none;
  }
  
  .input-icon, .input-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .input-name{
    position: absolute;
    top: 20%;
    font-size: 15px;
    transform: translateY(-50%);
    left: 40px;
    color: #7b7b7b;
  }
  
  .input-icon {
    left: 10px;
    color: #7b7b7b;
  }
  
  .input-toggle {
    right: 10px;
    color: #7b7b7b;
    cursor: pointer;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .options label {
    display: flex;
    align-items: center;
  }
  
  .options input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .options .forgot-password {
    color: #5800ac;
    text-decoration: none;
  }
  
  .options .forgot-password:hover {
    text-decoration: underline;
  }
  
  .login-button {
    background: linear-gradient(90deg, #8e2de2, #4a00e0);
    border: none;
    color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background 0.3s;
  }
  
  .login-button:hover {
    background: linear-gradient(90deg, #4a00e0, #8e2de2);
  }
  
  .register-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .register-link a {
    color: #5800ac;
    text-decoration: none;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }
  
  